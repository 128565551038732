import React from 'react';
import FAQ from "../../../components/FAQ";
import ItJson from '../../translations/it.json';

function Faqs() {
    const hideComponent = true;

    return (
        <FAQ
            faqTitleSection={ItJson.faqTitleSection}
            faqList={ItJson.faq}
            otherQuestions={ItJson.otherQuestions}
            notes={ItJson.notes}
            hideComponent={hideComponent}
        />
    )
}

export default Faqs;